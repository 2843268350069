import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy" >
            <h1>Privacy Policy</h1>
            <p><strong>Privacy Policy for Federation Of Customer Association (FACA)</strong></p>
            <p>Effective Date: 26/07/2024</p>
            <p>At the Federation Of Customer Association (FACA), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="http://facaindia.com">facaindia.com</a> or use our services.</p>

            <h2>1. Information We Collect</h2>
            <ul>
                <li><strong>Personal Data:</strong> Name, email address, phone number, etc.</li>
                <li><strong>Usage Data:</strong> IP address, browser type, operating system, pages visited, etc.</li>
                <li><strong>Cookies:</strong> To enhance user experience and track website usage.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>To provide, operate, and maintain our website and services.</li>
                <li>To improve, personalize, and expand our website and services.</li>
                <li>To communicate with you, including customer service and updates.</li>
                <li>To process transactions and send you related information.</li>
            </ul>

            <h2>3. Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your Personally Identifiable Information to outside parties except to trusted third parties who assist us in operating our website and conducting our business.</p>

            <h2>4. Security</h2>
            <p>We implement a variety of security measures to maintain the safety of your personal information.</p>

            <h2>5. Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal information. You can contact us to exercise these rights.</p>
        </div>
    );
};

export default PrivacyPolicy;
