import React from 'react'
import { Link } from 'react-router-dom'

function Subscritption() {
  return (
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Subscription List <span className="badge bg-orange">02</span></h2>
          <div className="container-box-top-header-right d-flex">
            <Link to="/add-subscription" className="single-product-btn me-2"><i className="fa fa-plus me-2" />Add Subscription</Link>
            <button className="excel-btn"><img src="img/excel.svg" />Download</button>
          </div>
        </div>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input type="search" name="search" placeholder="Search" />
              <button className="search-btn">Search</button>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{width: '100%'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>subscription <br />Type</th>
                  <th>User Name</th>
                  <th>Mobile No.</th>
                  <th>Registration date</th>
                  <th>Expire date</th>
                  <th>Amounts</th>
                  <th>Payment<br />Method</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1</th>
                  <td><span className="usertype">Yearly</span></td>
                  <td>Rakesh</td>
                  <td>+91 9876543211</td>
                  <td>28-04-2022</td>
                  <td>28-05-2022</td>
                  <td>₹ 5000</td>
                  <td>Online</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                      <div className="action_toggle_content product-edit">
                        <ul className="list-unstyled">
                          <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                          <li><a href="subscription-add.html" className="edit">Edit</a></li>
                          <li><a href="javascript:void" className="reject">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>2</th>
                  <td><span className="usertype">5 Years</span></td>
                  <td>Rakesh</td>
                  <td>+91 9876543211</td>
                  <td>28-04-2022</td>
                  <td>28-05-2022</td>
                  <td>₹ 5000</td>
                  <td>Online</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                      <div className="action_toggle_content product-edit">
                        <ul className="list-unstyled">
                          <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                          <li><a href="subscription-add.html" className="edit">Edit</a></li>
                          <li><a href="javascript:void" className="reject">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>3</th>
                  <td><span className="usertype">Lifetime</span></td>
                  <td>Rakesh</td>
                  <td>+91 9876543211</td>
                  <td>28-04-2022</td>
                  <td>28-05-2022</td>
                  <td>₹ 5000</td>
                  <td>Online</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                      <div className="action_toggle_content product-edit">
                        <ul className="list-unstyled">
                          <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                          <li><a href="subscription-add.html" className="edit">Edit</a></li>
                          <li><a href="javascript:void" className="reject">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>4</th>
                  <td><span className="usertype">Yearly</span></td>
                  <td>Rakesh</td>
                  <td>+91 9876543211</td>
                  <td>28-04-2022</td>
                  <td>28-05-2022</td>
                  <td>₹ 5000</td>
                  <td>Online</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                      <div className="action_toggle_content product-edit">
                        <ul className="list-unstyled">
                          <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                          <li><a href="subscription-add.html" className="edit">Edit</a></li>
                          <li><a href="javascript:void" className="reject">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )
}

export default Subscritption