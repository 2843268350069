import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions" >
            <h1>Terms and Conditions</h1>
            <p><strong>Terms and Conditions for Federation Of Customer Association (FACA)</strong></p>
            <p>Effective Date: 26/07/2024</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using our website, you accept and agree to be bound by the terms and provisions of this agreement. Additionally, when using our services, you shall be subject to any posted guidelines or rules applicable to such services.</p>

            <h2>2. Services Provided</h2>
            <p>The Federation Of Customer Association (FACA) aims to protect the rights of automobile customers by addressing concerns with automobile dealers, distributors, and manufacturers, ensuring customers receive quality service and products.</p>

            <h2>3. User Responsibilities</h2>
            <p>Users agree to use our website and services in compliance with all applicable laws and regulations. Users shall not engage in any activities that may harm or interfere with our website or services.</p>

            <h2>4. Limitation of Liability</h2>
            <p>FACA shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.</p>

            <h2>5. Changes to Terms</h2>
            <p>We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the website and services constitutes your acceptance of the revised terms.</p>
        </div>
    );
};

export default TermsAndConditions;
