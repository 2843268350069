import React from 'react'

function Home() {
  return (
    <div>
      <header>
        <div className="container">
          <div className="header-inner">
            <div className="row align-items-center">
              <div className="col-lg-3 col-6">
                <div className="logo">
                  <a href="javascript:void(0)">
                    <img src="img/logo.svg" alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-lg-9 col-6">
                <div className="header-menus">
                  <ul className="d-flex list-unstyled justify-content-end align-items-center">
                    <li>
                      <a href="#hero-section" className="active">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about-us-section">About us</a>
                    </li>
                    <li>
                      <a href="#service-section">Service</a>
                    </li>
                    <li>
                      <a href="#contact-section">Contact</a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.faca" className="site-btn btn-orange ms-5">
                        Download Now
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mobile-sidenav-btn">
                  <button className="mobile-toggle-btn">
                    <i className="fa fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-sidenav-content" id="mySidenav" style={{ width: 0 }}>
          <div className="mobile-sidenav-btn sidenav-close-btn">
            <button className="mobile-toggle-btn active">
              <i className="fa fa-close" />
            </button>
          </div>
          <ul className=" list-unstyled">
            <li>
              <a href="#hero-section" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="#about-us-section">About us</a>
            </li>
            <li>
              <a href="#service-section">Services</a>
            </li>
            <li>
              <a href="#contact-section">Contact</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.faca" className="site-btn light-green">
                Download Now
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main>
        {/* ======== Hero section start ===== */}
        <section id="hero-section" className="hero-section">
          <div className="slider-container">
            <div className="slider">
              <div className="slide">
                <img src="img/banner-img.png" alt="Slide 1" />
                <div className='slider-content'>
                  <h1 className='mb-3 h1'>Welcome to Federation Of Customer Association</h1>
                  <p>The aim of the Federation of Automobile Customer Association (FACA) is to protect the rights of every automobile customer. By addressing concerns with automobile dealers, distributors, and manufacturers. Their goal is to ensure that customers are treated fairly and receive the quality of service and products they expect when purchasing automobiles.</p>
                </div>
              </div>
              <div className="slide">
                <img src="img/banner-img.png" alt="Slide 1" />
                <div className='slider-content'>
                  <h1 className='mb-3 h1'>Welcome to Federation Of Customer Association</h1>
                  <p>The aim of the Federation of Automobile Customer Association (FACA) is to protect the rights of every automobile customer. By addressing concerns with automobile dealers, distributors, and manufacturers. Their goal is to ensure that customers are treated fairly and receive the quality of service and products they expect when purchasing automobiles.</p>
                </div>
              </div>
            </div>
            <div className="prev" onClick="prevSlide()">
              ❮
            </div>
            <div className="next" onClick="nextSlide()">
              ❯
            </div>
          </div>
        </section>
        {/* ======== Hero section End ===== */}
        {/* ======== about us start ===== */}
        <section id="about-us-section" className="about-us-section py-70">
          <div className="container">
            <div className="about-us-inner">
              <div className="row align-items-center">
                <div className="col-md-6 ps-0">
                  <div className="about-us-img">
                    <img src="img/logo.svg" alt="about-us" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-us-content similar-content-section">
                    <h4>About Us</h4>
                    <p>
                      The aim of the Federation of Automobile Customer Association is to protect the rights of every automobile customer. They do this by addressing concerns with automobile dealers, distributors, and manufacturers. Their goal is to ensure that customers are treated fairly and receive the quality of service and products they expect when purchasing automobiles. This includes advocating for transparent business practices, fair pricing, reliable product information, and efficient customer service. By engaging with dealers, distributors, and manufacturers, the association aims to foster a better environment for automobile customers, where their concerns are heard and addressed promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== about us end ===== */}
        {/* ======== app-banner start ===== */}
        <section id="service-section" className="app-banner-section">
          <div className="container">
            <div className="app-banner-section-inner">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <div className="app-banner-content-left">
                    <h2 className="mb-3">You can Have your Own App</h2>
                    <p className="mb-4">
                      Download the app and get the best discount
                    </p>
                    <a className="site-btn light-green" href="https://play.google.com/store/apps/details?id=com.faca">
                      Download Now
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="app-banner-content-right text-center">
                    <div className="app-banner-inner">
                      <img src="img/moblie-app.svg" alt="mobile-app" />
                    </div>
                    <div className="app-btn ">
                      <a href="#">
                        <img src="img/google-play.svg" alt="mobile-app" />
                      </a>
                      <a href="#">
                        <img src="img/app-store.svg" alt="mobile-app" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== app-banner end ===== */}
        <div className="scroll_top">
          <a href="javascript:void(0)" id="scroll-button" style={{}}>
            <i className="fa fa-angle-up" />
          </a>
        </div>
      </main>
      <footer id="contact-section" className='ms-0 new-footer'>
        <div className="container">
          <div className="footer-inner">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="footer-number">
                  <ul>
                    <li>
                      <a href="#"><i className="fa fa-map-marker me-2"></i>Corporate office : <br/>Shri Rampura Colony, Civil Lines 302006 Jaipur (Rajasthan)</a>
                    </li>
                      <li className='mb-2'>
                      <a href="#"><i className="fa fa-phone me-2"></i>+91 7849881370</a>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-logo">
                  <a href="#">
                    <img src="img/logo.svg" alt="footer-logo" />
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-follow-icon">
                  <ul className="list-unstyled d-flex">
                    <li>
                      <a href="https://www.facebook.com/faca.india">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/faca_india/">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@faca_india">
                        <i className="fa fa-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/faca_india">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 footer-border">
                <ul className="list-unstyled footer-list d-flex justify-content-center align-items-center">
                  <li>
                    <a href="#hero-section">Home</a>
                  </li>
                  <li>
                    <a href="#about-us-section">About</a>
                  </li>
                  <li>
                    <a href="#service-section">Service</a>
                  </li>
                  <li>
                    <a href="#contact-section">Contact</a>
                  </li>
                  <li>
                    <a href="/privacy" target='_blank'>Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/term" target='_blank'>Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/refund" target='_blank'>Refund Policy</a>
                  </li>
                  
                </ul>
              </div>
              <div className="footer-bottom">
                <p>© 2024 FACA. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
