import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './component/Auth/Login';
import Dashboard from './component/Dashboard';
import UserManager from './component/UserManager';
import Benifit from './component/Benifit';
import Contect from './component/Contect';
import Enquiry from './component/Enquiry';
import Help from './component/Help';
import HomeSlider from './component/HomeSlider';
import Plans from './component/Plans';
import Subscritption from './component/Subscritption';
import Notification from './component/Notification';
import VehicleDetails from './component/VehicleDetails';
import Settings from './component/Settings';
import Advertising from './component/Advertising.js';
import AdminUser from './component/AdminUser';
import AddHomeSlider from './component/Add Model/AddHomeSlider';
import AddAdmin from './component/Add Model/AddAdmin';
import AddNotification from './component/Add Model/AddNotification';
import AddSubscription from './component/Add Model/AddSubscription';
import AddPlan from './component/Add Model/AddPlan';
import PushNotification from './component/pushNotification';
import HomePage from './component/Home.js';
import Layout from './Layout'; // Import the Layout component
import PrivacyPolicy from './component/PrivacyPolicy .js';
import TermsAndConditions from './component/TermsAndConditions.js';
import RefundPolicy from './component/RefundPolicy.js';

const PrivateRoute = ({ children, isAuthenticated, role, requiredRole }) => {
  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  if (requiredRole && !requiredRole.includes(role)) {
    return <Navigate to="/dashboard" />; // Or an appropriate "not authorized" page
  }
  return children;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated and get their role
    const token = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole'); // Assuming role is stored in local storage
    if (token && userRole) {
      setIsAuthenticated(true);
      setRole(userRole);
    } else {
      setIsAuthenticated(false);
    }
  }, [role]);

  return (
    <Router>
      <Layout isAuthenticated={isAuthenticated} role={role}>
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <Login setIsAuthenticated={setIsAuthenticated} setRole={setRole} />
            }
          />
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/privacy"
            element={
             
                <PrivacyPolicy />
             
            }
          />
          <Route
            path="/term"
            element={
            
                <TermsAndConditions />
             
            }
          />
          <Route
            path="/refund"
            element={
           
                <RefundPolicy />
        
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'userManager']}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/usermanager"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'userManager']}>
                <UserManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/benifit"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'healper']}>
                <Benifit />
              </PrivateRoute>
            }
          />
          <Route
            path="/contect"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','healper']}>
                <Contect />
              </PrivateRoute>
            }
          />
          <Route
            path="/enquiry"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','healper']}>
                <Enquiry />
              </PrivateRoute>
            }
          />
          <Route
            path="/help"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','healper']}>
                <Help />
              </PrivateRoute>
            }
          />
          <Route
            path="/homeslider"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','healper']}>
                <HomeSlider />
              </PrivateRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','userManager']}>
                <Notification />
              </PrivateRoute>
            }
          />
          <Route
            path="/plans"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'userManager']}>
                <Plans />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'userManager']}>
                <Subscritption />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle-details"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin',"userManager"]}>
                <VehicleDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin', 'healper']}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/advertising"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin',"healper"]}>
                <Advertising />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-user"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin']}>
                <AdminUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-admin-user"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin']}>
                <AddAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-home-slider"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','userManger']}>
                <AddHomeSlider />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-notification"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','userManger']}>
                <AddNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-subscription"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','userManger']}>
                <AddSubscription />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-plan"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','userManger']}>
                <AddPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/pushNotification"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} role={role} requiredRole={['admin','healper']}>
                <PushNotification />
              </PrivateRoute>
            }
          />
          
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
