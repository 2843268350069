import React, {useState} from 'react'
import useAsync from '../hooks/useAsync'
import RequestServies from '../services/RequestServices'
import Modal from 'react-modal';
import BenifitUpdate from './Update Model/BenifitUpdate';
Modal.setAppElement('#root');
function Benifit() {

  const {data , error, isLoading, run} = useAsync(RequestServies.getAllBenifit);
  const count = data?.data?.length
console.log(data)
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [selectedEdit, setSelectedEdit] = useState(null);

  const handleEditDetails = (help) => {
    setSelectedEdit(help);
    setIsEditModalOpen(true);
  };


  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };
 
    

  return (
    <>
    <div className="right_col" role="main">
    <div className="title-box">
      <h2>Benifit <span className="badge bg-orange">{count}</span></h2>
      
    </div>
    <div className="sub-title-box">
      <div className="sub-title-box-left">
        <p>Benefit List</p>
      </div>
     
    </div>
    <div className="container-box px-0 user-manager">
      <div className="container-box-top-header px-4">
        
      </div>
      <div className="container-box-inner">
        <table id="example" className="table table-striped" style={{width: '100%'}}>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {data?.data?.map((add, index) => (
            <tr key={index}>
              <th>{index + 1}</th>
              <td>{add.userFor}</td>
              <td>{add.title}</td>
              <td>{add.description}</td>
              <td>
                <div className="action_toggle_outer">
                  <button className="action_toggle"  onClick={() => handleEditDetails(add)} ><i className="fa fa-edit" /></button>
                </div>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <BenifitUpdate Benifit={selectedEdit} closeModal={closeEditModal} onSuccess={run}/>
        </Modal>
  </div>
  </>
  )
}

export default Benifit