import requests from "./httpService";

const UserServices = {

    getAllUser : async () =>{
        return requests.get(`/user`);
    },
    updateUser : async (id,body) =>{
        return requests.put(`/user/update-status/${id}`,body);
    },
    getAllVehicle : async () =>{
        return requests.get(`/vehical`);
    },

    getContactDetail : async () =>{
        return requests.get(`/contact`);
    },
    updateContactDetail : async (body) =>{
        return requests.patch(`/contact`,body);
    }


}

export default UserServices;