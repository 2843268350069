import React from 'react'
import HelpTogal from '../TogelButton/HelpTogal'
function ViewEnquiry({ data  , closeModal, onSuccess}) {

    function formatDateTime(isoString) {
        const date = new Date(isoString);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return formattedDate;
    
      }

  return (
    <div className="modal fade viewbox edit-box show d-block" id="helpViewModal" tabIndex={-1} aria-labelledby="helpViewModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="helpViewModal">Details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal"  onClick={closeModal} aria-label="Close" />
        </div>
        <div className="modal-body px-5">
          <div className="d-box mt-3 pt-1">
            <ul className="d-flex list-unstyled justify-content-between">
              <li><span>Name : </span>{data?.name}</li>
              <li><span>Mobile No: </span>{data?.phone}</li>
              <li><span>Registered Date: </span>{formatDateTime(data?.createdAt)}</li>
              <li className="d-flex"><span>Status : </span> 
              <span className="status-toggle">
              <HelpTogal help={data} page="Enquiry" onSuccess={onSuccess} />
                </span>
                </li> 
            </ul>
          </div>
          <div className="address mt-4">
            <span>Message: </span>
            <span>{data?.message}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewEnquiry