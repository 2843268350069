import React from 'react'

function Footer({ name ,className }) {
  return (
    <footer>    <div className="footer-inner d-flex align-items-center justify-content-between">
    <div className="footer-inner-left">
      <p>© 2024 Made By <a href="https://www.truevalueinfosoft.com/" target="_blank">True Value Infosoft</a></p>
    </div>
    <div className="footer-inner-right">
      <ul className="d-flex align-items-center list-unstyled">
        <li><a href="#">{name}</a></li>
        <li><a href="#"><i className={className} /></a></li>
      </ul>
    </div>
  </div>
  
  </footer>

  )
}

export default Footer