import React,{useState,useEffect} from 'react'
import UserServices from '../services/UserServices'
import useAsync from '../hooks/useAsync'
function Contect() {
  const {data , error, isLoading} = useAsync(UserServices.getContactDetail);
  console.log(data)

  const [formValues, setFormValues] = useState({
    phone: '',
    whatsApp: '',
    faceBook: '',
    linkdin: '',
    instagram: ''
  });

  // Update form values when data is fetched
  useEffect(() => {
    if (data.data) {
      setFormValues({
        phone: data.data.phone || '',
        whatsApp: data.data.whatsApp || '',
        faceBook: data.data.faceBook || 'https://',
        linkdin: data.data.linkdin || 'https://',
        instagram: data.data.instagram || 'https://'
      });
    }
  }, [data]);

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(formValues)
      await UserServices.updateContactDetail(formValues); // Assuming updateContactDetail is a method in UserServices
      alert('Contact details updated successfully');
    } catch (error) {
      console.error('Failed to update contact details', error);
      alert('Failed to update contact details');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading contact details</div>;
  }

  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Contact us</h2>
        </div>
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-field">
                      <label>Phone</label>
                      <input 
                        type="text" 
                        name="phone" 
                        className="form-control" 
                        value={formValues.phone} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-field">
                      <label>Whatsapp</label>
                      <input 
                        type="text" 
                        name="whatsApp" 
                        className="form-control" 
                        value={formValues.whatsApp} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Facebook Link</label>
                      <div className="input-link-outer">
                        <input 
                          type="text" 
                          name="faceBook" 
                          className="form-control" 
                          value={formValues.faceBook} 
                          onChange={handleInputChange} 
                        />
                        <button type="button" className="link-copy-btn"><i className="fa fa-paperclip" /></button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Linkedin Link</label>
                      <div className="input-link-outer">
                        <input 
                          type="text" 
                          name="linkdin" 
                          className="form-control" 
                          value={formValues.linkdin} 
                          onChange={handleInputChange} 
                        />
                        <button type="button" className="link-copy-btn"><i className="fa fa-paperclip" /></button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Instagram Link</label>
                      <div className="input-link-outer">
                        <input 
                          type="text" 
                          name="instagram" 
                          className="form-control" 
                          value={formValues.instagram} 
                          onChange={handleInputChange} 
                        />
                        <button type="button" className="link-copy-btn"><i className="fa fa-paperclip" /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="submit-green-btn">SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contect