import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import RequestServies from '../services/RequestServices';
import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import ViewAdevertising from './View Model/ViewAdevertising';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function Advertising() {
  const { data, error, isLoading, run } = useAsync(RequestServies.getAdevertis);
  const count = data?.data?.length;

  const [isAddViewModalOpen, setIsAddViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAdd, setSelectedAdd] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAdds, setFilteredAdds] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setFilteredAdds(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredAdds(data?.data || []);
    } else {
      const filtered = data?.data?.filter((add) =>
        add.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        add.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        add.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        add.website.toLowerCase().includes(searchTerm.toLowerCase()) ||
        add.message.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAdds(filtered);
    }
  }, [searchTerm, data]);

  const handleViewDetails = (add) => {
    setSelectedAdd(add);
    setIsAddViewModalOpen(true);
  };

  const handleDelete = (add) => {
    setSelectedAdd(add);
    setIsDeleteModalOpen(true);
  };

  const closeAddViewModal = () => {
    setIsAddViewModalOpen(false);
    setSelectedAdd(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedAdd(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;
  }

  const handleDownload = () => {
    const adsToDownload = filteredAdds.map((add) => ({
      'User Name': add.name,
      'Company Name': add.companyName,
      'Email': add.email,
      'Website': add.website,
      'Registered Date': formatDateTime(add.createdAt),
      'Message': add.message,
      'Price': add?.type,
      'Status': add.status
    }));

    const worksheet = XLSX.utils.json_to_sheet(adsToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Advertisements');

    XLSX.writeFile(workbook, 'advertisements.xlsx');
  };

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Advertising<span className="badge bg-orange">{count}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" alt="Download" />Download</button>
          </div>
        </div>
        <div className="container-box px-0 user-manager">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input
                type="search"
                name="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th>Registered Date</th>
                  <th>Message</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAdds.map((add, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{add.name}</td>
                    <td>{add.companyName}</td>
                    <td>{add.email}</td>
                    <td>{add.website}</td>
                    <td>{formatDateTime(add.createdAt)}</td>
                    <td>{add.message}</td>
                    <td>{add?.type}</td>
                    <td className="status-toggle">
                      <HelpTogal help={add} page="Add" onSuccess={run} />
                    </td>
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleViewDetails(add)}>View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(add)}>Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isAddViewModalOpen}
          onRequestClose={closeAddViewModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewAdevertising data={selectedAdd} closeModal={closeAddViewModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedAdd} page="Add" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  );
}

export default Advertising;