import React, { useState, useEffect } from 'react'
import useAsync from '../hooks/useAsync'
import RequestServies from '../services/RequestServices'
import HelpTogal from './TogelButton/HelpTogal'
import ViewHelp from './View Model/ViewHelp'
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton'
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function Help() {
  const { data, error, isLoading, run } = useAsync(RequestServies.getAllHelp);
  const count = data?.data?.length;

  const [isHelpViewModalOpen, setIsHelpViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedHelp, setSelectedHelp] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredHelp, setFilteredHelp] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setFilteredHelp(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredHelp(data?.data || []);
    } else {
      const filtered = data?.data?.filter((help) =>
        help?.userType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        help?.vehicleNo?.vehicleNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        help?.issue.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredHelp(filtered);
    }
  }, [searchTerm, data]);

  const handleViewDetails = (help) => {
    setSelectedHelp(help);
    setIsHelpViewModalOpen(true);
  };

  const handleDelete = (help) => {
    setSelectedHelp(help);
    setIsDeleteModalOpen(true);
  };

  const closeHelpViewModal = () => {
    setIsHelpViewModalOpen(false);
    setSelectedHelp(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedHelp(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleDownload = () => {
    const helpsToDownload = filteredHelp.map(help => ({
      'User Type': help.userType,
      'Vehical No': help.vehicleNo?.vehicleNumber,
      'Phone': help.user?.mobileNo,
      'Issue': help.issue,
      'Registered Date': help.vehicleNo?.vehicaleRegistration,
      'Message': help.message,
      'Status': help.status
    }));

    const worksheet = XLSX.utils.json_to_sheet(helpsToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Help Requests');

    XLSX.writeFile(workbook, 'help_requests.xlsx');
  };

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Help <span className="badge bg-orange">{count}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Plans List</p>
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" alt="Download" />Download</button>
          </div>
        </div>
        <div className="container-box px-0 user-manager">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input
                type="search"
                name="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Type</th>
                  <th>Vehical no</th>
                  <th>Issue</th>
                  <th>Registered Date</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredHelp.map((help, index) => (
                  <tr key={help._id}>
                    <th>{index + 1}</th>
                    <td><span className="usertype">{help?.userType}</span></td>
                    <td>{help?.vehicleNo?.vehicleNumber}</td>
                    <td>{help?.issue}</td>
                    <td>{help?.vehicleNo?.vehicaleRegistration}</td>
                    <td>{help?.message}</td>
                    <td className="status-toggle">
                      <HelpTogal help={help} page="Help" onSuccess={run} />
                    </td>
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleViewDetails(help)}>View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(help)}>Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isHelpViewModalOpen}
          onRequestClose={closeHelpViewModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewHelp help={selectedHelp} closeModal={closeHelpViewModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedHelp} page="Help" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  )
}

export default Help;
