import React, { useEffect, useState } from 'react'
import useAsync from '../hooks/useAsync'
import UserServices from '../services/UserServices'
import ViewVehical from './View Model/ViewVehical';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function VehicleDetails() {
  const { data, error, isLoading } = useAsync(UserServices.getAllVehicle);
  const count = data?.data?.length;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setFilteredVehicles(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredVehicles(data?.data || []);
    } else {
      const filtered = data?.data?.filter((vehical) =>
        vehical?.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehical.vehicleNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vehical.vehicalModel.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredVehicles(filtered);
    }
  }, [searchTerm, data]);

  const handleViewDetails = (vehical) => {
    setSelectedVehicle(vehical);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVehicle(null);
  };

  const handleDownload = () => {
    const vehiclesToDownload = filteredVehicles.map(vehical => ({
      'Vehicle Type': vehical.vehicalType,
      'Name': vehical?.user?.name,
      'Vehicle No.': vehical.vehicleNumber,
      'Vehicle Model': vehical.vehicalModel,
      'Manufacture Year': vehical.vehicalManufactureYear,
      'Registration Year': vehical.vehicaleRegistration,
      "Vehicle Make" : vehical.vehicalMaker , "RC Document" : vehical.rcDoc, " Insurance Document" : vehical.insuranceDocument , "Fitness Certificate" : vehical.fitnaceCirtificate 
    }));

    const worksheet = XLSX.utils.json_to_sheet(vehiclesToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Vehicles');

    XLSX.writeFile(workbook, 'vehicles.xlsx');
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Vehicle Details <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          <p>Vehicle Details</p>
        </div>
        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" alt="Download" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Vehicle Type</th>
                <th>Name</th>
                <th>Vehicle No.</th>
                <th>Vehicle Model</th>
                <th>Manufacture Year</th>
                <th>Registration Year</th>
                <th>Buy Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredVehicles.map((vehical, index) => (
                <tr key={vehical._id}>
                  <td>{index + 1}</td>
                  <td><span className="usertype">{vehical.vehicalType}</span></td>
                  <td>{vehical?.user?.name}</td>
                  <td>{vehical?.vehicleNumber}</td>
                  <td>{vehical?.vehicalModel}</td>
                  <td>{vehical?.vehicalManufactureYear}</td>
                  <td>{vehical?.vehicaleRegistration}</td>
                  <td className="status-toggle">
                    <div className="check-box">
                      <input type="checkbox" defaultChecked />
                    </div>
                  </td>
                  <td>
                    <button
                      className="view-details-btn"
                      onClick={() => handleViewDetails(vehical)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Vehicle Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewVehical vehical={selectedVehicle} closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default VehicleDetails;
