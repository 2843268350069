import requests from "./httpService";

const HomesliderServices = {

    getHomeSlider : async () =>{
        return requests.get(`/homeslider`);
    },
    updateHomeSlider : async (id,body) =>{
        return requests.patch(`/homeslider/${id}`,body);
    },

    createHomeSlider : async (body) =>{
        return requests.post(`/homeslider`,body);
    },

    deleteHomeSlider : async (id) =>{
        return requests.delete(`/homeslider/${id}`);
    }
 

}

export default HomesliderServices;