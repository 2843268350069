import requests from "./httpService";

const NotificationServices = {

    getNotification : async () =>{
        return requests.get(`/notification`);
    },
    updateNotification : async (id,body) =>{
        return requests.patch(`/notification/${id}`,body);
    },

    createNotification : async (body) =>{
        return requests.post(`/notification`,body);
    },
    deleteNotification : async (id) =>{
        return requests.delete(`/notification/${id}`);
    },
    sendPushNotification : async (body) =>{
        console.log(body)
        return requests.post(`/notification/sendNotification`,body)
    }


}

export default NotificationServices;