// SideBar.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const menuItems = [
  { to: '/dashboard', icon: 'fa fa-home', text: 'Dashboard', roles: ['admin', 'userManager', 'healper'] },
  { to: '/usermanager', icon: 'fa fa-user-circle-o', text: 'User Manager', roles: ['admin', 'userManager'] },
  { to: '/benifit', icon: 'fa fa-file-text', text: 'Benefit', roles: ['admin', 'healper'] },
  { to: '/contect', icon: 'fa fa-phone', text: 'Contact Us', roles: ['admin', 'healper'] },
  { to: '/enquiry', icon: 'fa fa-search', text: 'Enquiry', roles: ['admin', 'healper'] },
  { to: '/help', icon: 'fa fa-info-circle', text: 'Help', roles: ['admin', 'healper'] },
  { to: '/homeslider', icon: 'fa fa-film', text: 'Home Slider', roles: ['admin', 'healper'] },
  { to: '/notification', icon: 'fa fa-bell-o', text: 'Notification', roles: ['admin', 'userManager'] },
  { to: '/plans', icon: 'fa fa-rupee', text: 'Plans', roles: ['admin', 'userManager'] },
  { to: '/subscription', icon: 'fa fa-bell-o', text: 'Subscription', roles: ['admin', 'userManager'] },
  { to: '/vehicle-details', icon: 'fa fa-car', text: 'Vehicle-details', roles: ['admin', 'userManager'] },
  { to: '/settings', icon: 'fa fa-gear', text: 'Settings', roles: ['admin', 'healper'] },
  { to: '/advertising', icon: 'fa fa-file-text', text: 'Advertising', roles: ['admin', 'healper'] },
  { to: '/admin-user', icon: 'fa fa-film', text: 'Admin-User', roles: ['admin'] },
  { to: '/pushNotification', icon: 'fa fa-film', text: 'Push Notification', roles: ['admin', 'healper'] }
];

function SideBar({ role }) {
  return (
    <div className="col-md-3 left_col">
      <div className="left_col scroll-view">
        <div className="navbar nav_title" style={{border: 0}}>
          <NavLink to="/dashboard" className="site_title">
            <img src="img/logo.svg" alt="Logo" />
          </NavLink>
        </div>
        <div className="clearfix" />
        {/* sidebar menu */}
        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
          <div className="menu_section">
            <ul className="nav side-menu">
              {menuItems.filter(item => item.roles.includes(role)).map((item, index) => (
                <li key={index}>
                  <NavLink to={item.to} activeClassName="active">
                    <i className={item.icon} /> {item.text}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* /sidebar menu */}
      </div>
    </div>
  );
}

export default SideBar;
