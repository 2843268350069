import React from 'react'
import { Link } from 'react-router-dom'
function Plans() {
  return (
    <>
    <div className="right_col" role="main">
    <div className="title-box">
      <h2>Plans List <span className="badge bg-orange">03</span></h2>
      <div className="container-box-top-header-right d-flex">
        <Link className="round-add-btn" to="/add-plan"><img src="img/plus.svg" alt="" /></Link>
      </div>
    </div>
    <div className="sub-title-box">
      <div className="sub-title-box-left">
        <p>Plans List</p>
      </div>
      <div className="sub-title-box-right">
        <button className="excel-btn"><img src="img/excel.svg" />Download</button>
      </div>
    </div>
    <div className="container-box px-0 user-manager">
      <div className="container-box-top-header px-4">
        
      </div>
      <div className="container-box-inner">
        <table id="example" className="table table-striped" style={{width: '100%'}}>
          <thead>
            <tr>
              <th>#</th>
              <th>Package Name</th>
              <th>Amount</th>
              <th>User Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Description</th>
              <th>status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <td><span className="usertype">Yearly</span></td>
              <td>₹ 99</td>
              <td>User</td>
              <td>28-04-2022</td>
              <td>28-06-2022</td>
              <td>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document</td>
              <td className="status-toggle">
                <div className="check-box">
                  <input type="checkbox" defaultChecked />
                </div>
              </td>
              <td>
                <div className="action_toggle_outer">
                  <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                  <div className="action_toggle_content product-edit" style={{display: 'none'}}>
                    <ul className="list-unstyled">
                      <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                      <li><a href="plans-add.html" className="edit">Edit</a></li>
                      <li><a href="javascript:void" className="reject">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td><span className="usertype">5 Year</span></td>
              <td>₹ 449</td>
              <td>User</td>
              <td>28-04-2022</td>
              <td>28-06-2022</td>
              <td>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document</td>
              <td className="status-toggle">
                <div className="check-box">
                  <input type="checkbox" defaultChecked />
                </div>
              </td>
              <td>
                <div className="action_toggle_outer">
                  <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                  <div className="action_toggle_content product-edit" style={{display: 'none'}}>
                    <ul className="list-unstyled">
                      <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                      <li><a href="plans-add.html" className="edit">Edit</a></li>
                      <li><a href="javascript:void" className="reject">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>2</th>
              <td><span className="usertype">Lifetime</span></td>
              <td>₹ 999</td>
              <td>User</td>
              <td>28-04-2022</td>
              <td>28-06-2022</td>
              <td>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document</td>
              <td className="status-toggle">
                <div className="check-box">
                  <input type="checkbox" defaultChecked />
                </div>
              </td>
              <td>
                <div className="action_toggle_outer">
                  <button className="action_toggle"><i className="fa fa-ellipsis-v" /></button>
                  <div className="action_toggle_content product-edit" style={{display: 'none'}}>
                    <ul className="list-unstyled">
                      <li><a href="javascript:void" className="close_toggle"><i className="fa fa-close" /></a></li>
                      <li><a href="plans-add.html" className="edit">Edit</a></li>
                      <li><a href="javascript:void" className="reject">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </>
  )
}

export default Plans