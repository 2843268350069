import React, { useEffect, useState } from 'react'
import useAsync from '../hooks/useAsync'
import UserServices from '../services/UserServices'
import ViewUser from './View Model/ViewUser';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function UserManager() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllUser);
  const count = data?.users?.length;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (data?.users) {
      setFilteredUsers(data.users);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredUsers(data?.users || []);
    } else {
      const filtered = data?.users?.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.mobileNo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, data]);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleDownload = () => {
    const usersToDownload = filteredUsers.map(user => ({
      'User Type': user.userType,
      'Name': user.name,
      'Mobile No.': user.mobileNo,
      'Email': user.email,
      'Address': user.address,
      "City" : user.city , "State" : user.state , "Pincode" : user.pincode , "Details about " : user?.profile?.detailAbout , "GST No.":user?.profile?.gstNo , "CIN No": user?.profile?.cin , "Gst  certificate doc" : user?.profile?.gstCertificate, "Trademark Document" : user?.profile?.trademark," Photo":user.image , "Cin certificate": user?.profile?.cinCertificate,
      'Payment': user?.plan?.planName,
      'Registration Date': user.createdAt
    }));

    const worksheet = XLSX.utils.json_to_sheet(usersToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    XLSX.writeFile(workbook, 'users.xlsx');
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>User Manager <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          <p>User List</p>
        </div>
        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" alt="Download" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Type</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Email</th>
                <th>Address</th>
                <th>Payment</th>
                <th>Registration Date</th>
                <th>Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td><span className="usertype">{user.userType}</span></td>
                  <td>{user.name}</td>
                  <td>{user.mobileNo}</td>
                  <td>{user.email}</td>
                  <td className="address">{user.address}</td>
                  <td>{user?.plan?.planName}</td>
                  <td>{user.createdAt}</td>
                  <td className="status-toggle"><UserStatus user={user} onSuccess={run} /></td>
                  <td>
                    <button
                      className="view-details-btn"
                      onClick={() => handleViewDetails(user)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default UserManager;
