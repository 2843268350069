import React from 'react'
import HelpTogal from '../TogelButton/HelpTogal'

function ViewHelp({ help  , closeModal ,onSuccess}) {
  return (
    <div className="modal fade viewbox edit-box show d-block" id="helpViewModal" tabIndex={-1} aria-labelledby="helpViewModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="helpViewModal">Details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal"  onClick={closeModal} aria-label="Close" />
        </div>
        <div className="modal-body px-5">
          <div className="d-box mt-3 pt-1">
            <ul className="d-flex list-unstyled justify-content-between">
              <li><span>User Type : </span>{help?.userType}</li>
              <li><span>Vehicle Number: </span>{help?.vehicleNo?.vehicleNumber}</li>
              <li><span>Registered Date: </span>{help?.vehicleNo?.vehicaleRegistration}</li>
              <li className="d-flex"><span>Status : </span><span className="status-toggle">
                  <HelpTogal help={help} page="Help" onSuccess={onSuccess}/>
                </span></li>
              <li><span>Phone : </span>{help?.user?.mobileNo}</li>
              <li><span>Issue:</span>{help?.issue}</li>
            </ul>
          </div>
          <div className="address mt-4">
            <span>Message: </span>
            <span>{help?.message}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewHelp