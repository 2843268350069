import React, { useState } from 'react';
import RequestServies from '../../services/RequestServices';
import NotificationServices from '../../services/NotificationServices';
import HomesliderServices from '../../services/HomeSliderServices';
import useAsync from '../../hooks/useAsync';

const HelpTogal = ({ help, page ,onSuccess}) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(help?.status === 'show');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = !isChecked ? 'show' : 'hide';
    setIsChecked(!isChecked);
    

    if (page === 'Help') {
      const res = await RequestServies.updateHelp(help._id, {
        status: newStatus
      });

      onSuccess();
    }else if (page === 'Enquiry') {
      const res = await RequestServies.updateEnquiry(help._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'Add') {
      const res = await RequestServies.updateAdevertis(help._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'notification') {
      const res = await NotificationServices.updateNotification(help._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'HomeSlider') {
      const res = await HomesliderServices.updateHomeSlider(help._id, {
        status: newStatus
      });
      onSuccess();
    }

    
  };

  return (
   
      <div className="check-box">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    
  );
};

export default HelpTogal;
