import React, { useState, useEffect } from 'react';
import HelpTogal from '../TogelButton/HelpTogal';
import RequestServies from '../../services/RequestServices';

function BenifitUpdate({ Benifit, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    userFor: 'all',
  });

  useEffect(() => {
    if (Benifit) {
      setFormValues({
        title: Benifit?.title || '',
        description: Benifit?.description || '',
        userFor: Benifit?.userFor || 'all',
      });
      
    }
  }, [Benifit]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await RequestServies.updateBenifit(Benifit.id, formValues);
      alert('Benifit updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Failed to update Benifit', error);
      alert('Failed to update Benifit');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Benifit Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
            <div className="container-box-inner">
        <div className="page-details">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="input-field">
                  <label>Tittle</label>
                  <input type="text" name="title" className="form-control" value={formValues.title} onChange={handleInputChange} placeholder="" />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="input-field">
                  <label>Description</label>
                  <textarea className="form-control video-desc" name='description' value={formValues.description} onChange={handleInputChange} />
                </div>
              </div>
            </div>
            <button className="submit-green-btn">Submit</button>
          </form>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenifitUpdate;
