import React,{useState ,useEffect,useRef } from 'react'
import useAsync from '../hooks/useAsync'
import AdminServices from '../services/AdminServices'


function Settings() {
  const {data , error, isLoading,run} = useAsync(AdminServices.getMyProfile);
console.log(data)
const [formValues, setFormValues] = useState({
  mobileNo: '',
  name: '',
  email: '',
});

const [formValues2, setFormValues2] = useState({
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
});

const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef(null);
// Update form values when data is fetched
useEffect(() => {
  if (data) {
    setFormValues({
      phone: data.mobileNo || '',
      name: data.name || '',
      email: data.email || '',
    });

    if (data.image) {
      setPreviewUrl(data.image); // If profile image URL exists in data
    }
    
  }
}, [data]);

// Handle form input changes
const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormValues({
    ...formValues,
    [name]: value
  });
};

const handleInputChange2 = (event) => {
  const { name, value } = event.target;
  setFormValues2({
    ...formValues2,
    [name]: value
  });
};

const handleFileChange = async(event) => {
  const file = event.target.files[0];
  setSelectedFile(file);
  setPreviewUrl(URL.createObjectURL(file));

  const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await AdminServices.updateMe(formData);
      console.log(response) // Assuming updateProfileImage is a method in AdminServices
      localStorage.setItem('image', response.image);
      alert('Profile image updated successfully');
      run();
    } catch (error) {
      console.error('Failed to update profile image', error);
      alert('Failed to update profile image');
    }

};


// Handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    console.log(formValues)
    await AdminServices.updateMe(formValues); // Assuming updateContactDetail is a method in UserServices
    alert('Contact details updated successfully');
  } catch (error) {
    console.error('Failed to update contact details', error);
    alert('Failed to update contact details');
  }
};

const handleSubmit2 = async (event) => {
  event.preventDefault();
  try {
    if(formValues2.newPassword !== formValues2.confirmPassword){
      alert('Password does not match')
    }else{
      await AdminServices.changePassword(formValues2); // Assuming updateContactDetail is a method in UserServices
      alert('Contact details updated successfully');
    }
   
  } catch (error) {
    console.error('Failed to update contact details', error);
    alert('Failed to update contact details');
  }
};




if (isLoading) {
  return <div>Loading...</div>;
}

if (error) {
  return <div>Error loading contact details</div>;
}

  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Profile</h2>
        </div>
        <div className="container-box p-0 profile-container py-0">
          <div className="profile-header">
            <div className="profile-img-outer">
            <img src={process.env.REACT_APP_URL + previewUrl} alt="Profile" />
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <button
                className="profile-upload-btn"
                onClick={() => fileInputRef.current.click()}
              >
                <i className="fa fa-pencil" />
              </button>
             
            </div>
           
          </div>
          <div className="container-box-inner p-5">
            <div className="row">
              <div className="col-md-6">
                <div className="page-details profile-details">
                  <h4>User details</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="input-field">
                      <label>Name</label>
                      <input type="text" name="name" value={formValues.name} 
                        onChange={handleInputChange}  className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>Phone</label>
                      <input type="text" name="phone" value={formValues.phone} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>Email</label>
                      <input type="email" name="email" value={formValues.email} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <button className="submit-green-btn">SUBMIT</button>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="page-details profile-details">
                  <h4>Change your password</h4>
                  <form onSubmit={handleSubmit2} >
                    <div className="input-field">
                      <label>Old Password</label>
                      <input type="password" name="oldPassword" value={formValues2.oldPassword} 
                        onChange={handleInputChange2} className="form-control" placeholder="Enter old password" />
                    </div>
                    <div className="input-field">
                      <label>New Password</label>
                      <input type="password" name="newPassword" value={formValues2.newPassword} 
                        onChange={handleInputChange2} className="form-control" placeholder="Enter new password" />
                    </div>
                    <div className="input-field">
                      <label>Confirm password</label>
                      <input type="password" name="confirmPassword" value={formValues2.confirmPassword} 
                        onChange={handleInputChange2} className="form-control" placeholder="Confirm new password" />
                    </div>
                    <button className="list-banner-btn submit-green-btn">SAVE CHANGE</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      </>
  )
}

export default Settings