import React from 'react'
import { Link } from 'react-router-dom'
function AddPlan() {
  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Plans add</h2>
          <div className="container-box-top-header-right">
            <Link className="list-banner-btn" to="/plans">Plans List</Link>
          </div>
        </div>
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="page-details">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-field">
                      <label> Package Name</label>
                      <input type="text" name="name" className="form-control" defaultValue placeholder="Weekly" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-field">
                      <label>Amount</label>
                      <input type="text" name="title" className="form-control" defaultValue placeholder />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-field">
                      <label>Start date</label>
                      <input type="date" name="name" className="form-control" defaultValue placeholder="New Product" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-field">
                      <label>End Date</label>
                      <input type="date" name="title" className="form-control" defaultValue placeholder />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-field">
                      <label>Status</label>
                      <div className="barcode-range-inner-field">
                        <p>Active</p>
                        <div className="status-toggle">
                          <div className="check-box">
                            <input type="checkbox" defaultChecked />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-field">
                      <div className="input-field">
                        <label>Package Type</label>
                        <select className="form-control">
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-field">
                      <label>Plan Expire</label>
                      <input type="date" name="title" className="form-control" defaultValue placeholder />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Description</label>
                      <textarea className="form-control video-desc" defaultValue={"                                  "} />
                    </div>
                  </div>
                </div>
                <button className="submit-green-btn">SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPlan