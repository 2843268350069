import React, { useState } from 'react';
import UserServices from '../../services/UserServices';
const UserStatus = ({ user ,onSuccess}) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(user?.status === 'active');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = !isChecked ? 'active' : 'Inactive';
    setIsChecked(!isChecked);
    
      const res = await UserServices.updateUser(user._id, {
        status: newStatus
      });

      if(!res.status === true){
        alert('There is some error')
        onSuccess();
      }

      
        
  };

  return (
   
      <div className="check-box">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    
  );
};

export default UserStatus;
