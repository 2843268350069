import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import NotificationServices from '../../services/NotificationServices';
function AddNotification() {

  
  const [formValues, setFormValues] = useState({
    title: '',
    message: '',
    image: '',
    linkUrl: '',
    userFor: '',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path


  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };
//   // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }
      console.log(formValues)
      await NotificationServices.createNotification(formData); // Assuming updateContactDetail is a method in UserServices
      alert('Notification Added Successfully');
      } catch (error) {
      console.error('Failed to update contact details', error);
      alert('Failed to add Notification details');
    }
  };

  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Notification Add</h2>
          <div className="container-box-top-header-right">
            <Link className="list-banner-btn" to="/notification">Notification List</Link>
          </div>
        </div>
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-9 col-md-6">
                    <div className="input-field">
                      <label>Tittle</label>
                      <input type="text" name="title" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="New notification" />
                    </div>
                    <div className="input-field">
                      <label>Link</label>
                      <input type="text" name="linkUrl" className="form-control"  value={formValues.linkUrl} 
                        onChange={handleInputChange}  placeholder="www.example.com" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="input-field">
                      <label>Select User</label>
                      <select className="form-control" value={formValues.userFor} 
                        onChange={handleInputChange} name="userFor">
                        <option value="all">All</option>
                        <option value="customer">Customer</option>
                        <option value="dealer">Dealer</option>
                        <option value="serviceCenter">Service Center</option>
                        <option value="manufacturer">manufacturer</option>
                      </select>
                      
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Description</label>
                      <textarea className="form-control video-desc" name="message"  value={formValues.message} 
                        onChange={handleInputChange} placeholder='new notification' />                           
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Upload<span className="red">*</span></label>
                      <input type="file" name="image" className="form-control" onChange={handleFileChange} />
                      <p><span>Drop an image or Paste URL (upto resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                      <div className="file-preview text-center">
                        <img id="uploadFile" src={previewImage} alt="your image" />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="submit-green-btn">Send Notification</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNotification