import React, { useState, useEffect } from 'react';
import HelpTogal from '../TogelButton/HelpTogal';
import HomesliderServices from '../../services/HomeSliderServices';

function HomeSliderUpdate({ notification, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    title: '',
    image: '',
    status: '',
    userFor: 'all',
    sequence: '',
    link: '',
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path

  useEffect(() => {
    if (notification) {
      setFormValues({
        title: notification?.title || '',
        image: notification?.image || '',
        status: notification?.status || 'hide',
        userFor: notification?.userFor || 'all',
        sequence: notification?.sequence || '',
        link: notification?.link || '',
      });
      if (notification.image) {
        setPreviewImage(process.env.REACT_APP_URL + notification.image);
      }
    }
  }, [notification]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (key !== 'image' || formValues[key] instanceof File) {
          formData.append(key, formValues[key]);
        }
      }

      await HomesliderServices.updateHomeSlider(notification.id, formData);
      alert('Home slider updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Failed to update home slider', error);
      alert('Failed to update home slider');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">HomeSlider Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-13 col-sm-12">
                        <div className="input-field">
                          <label>Title</label>
                          <input type="text" name="title" className="form-control" value={formValues.title} onChange={handleInputChange} placeholder="New notification" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Select User</label>
                          <select className="form-control" value={formValues.userFor} onChange={handleInputChange} name="userFor">
                            <option value="all">All</option>
                            <option value="customer">Customer</option>
                            <option value="dealer">Dealer</option>
                            <option value="serviceCenter">Service Center</option>
                            <option value="manufacturer">Manufacturer</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Select Sequence</label>
                          <select className="form-control" value={formValues.sequence} onChange={handleInputChange} name="sequence">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Link</label>
                          <div className="input-link-outer">
                            <input type="text" name="link" value={formValues.link} onChange={handleInputChange} className="form-control" defaultValue="https://" />
                            <button className="link-copy-btn"><i className="fa fa-paperclip" /></button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="input-field">
                          <label>Upload<span className="red">*</span></label>
                          <input type="file" name="image" className="form-control" onChange={handleFileChange} />
                          <p><span>Drop an image or Paste URL (up to resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                          <div className="file-preview text-center">
                            <img id="uploadFile" src={previewImage} alt="your image" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 ps-5">
                        <div className="input-field">
                          <label>Status</label>
                          <div className="barcode-range-inner-field">
                            <p>Active/Inactive</p>
                            <div className="status-toggle">
                              <HelpTogal help={notification} page="HomeSlider" onSuccess={onSuccess} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="submit-green-btn">Update HomeSlider</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSliderUpdate;
