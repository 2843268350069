import React from 'react'
function ViewVehical({ vehical  , closeModal}) {
  if (!vehical) return null;



  return (
    <div className="modal edit-box show d-block" id="ViewModel"  aria-labelledby="ViewModelLabel" role="dialog">
    <div className="modal-dialog  modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header Vehicle-details-header">
          <h5 className="modal-title ">Details</h5>
           <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            
        </div>
        <div className="modal-body ">
          <div className="details-content">
            <div className="row ">
              <div className="col-md-4">
                <p className="details">Name : <span className="model-deails"> {vehical?.user?.name}</span></p>
              </div>
              <div className="col-md-4">
                <p className="details">Vehicle Type:   <span className="model-deails">{vehical?.vehicalType == "personal"? "Personal":"Commercial"}</span></p>
              </div>
              <div className="col-md-4">
                <p className="details">Vehicle Number:  <span className="model-deails"> {vehical.vehicleNumber}</span></p>
              </div>
              <div className="col-md-4">
                <p className="details">Vehicle Make : <span className="model-deails">  {vehical.vehicalModel} </span></p>
              </div>
              <div className="col-md-4">
                <p className="details">Vehicle Model : <span className="model-deails">  {vehical.vehicalManufactureYear}</span></p>
              </div>
              <div className="col-md-4">
                <p className="details">Vehicle Manufacturing Year:  <span className="model-deails"> {vehical.vehicalManufactureYear} </span></p>
              </div>
              <div className="col-md-12">
                <p className="details">Vehicle Registration Year : <span className="model-deails"> {vehical.vehicaleRegistration}</span></p>
              </div>
              <div className="col-md-4">
                <a href={process.env.REACT_APP_URL + vehical.rcDoc} target='_blank'>
                <button type="button" className="Upload-document" data-bs-dismiss="modal"><i className="fa fa-file-pdf-o" />RC Document</button>
                </a>
              </div>
              <div className="col-md-4">
              <a href={process.env.REACT_APP_URL + vehical.insuranceDocument } target='_blank'>
                <button type="button" className="Upload-document" data-bs-dismiss="modal"><i className="fa fa-file-pdf-o" />Insurance Document</button>
              </a>
              </div>
              <div className="col-md-4">
              <a href={process.env.REACT_APP_URL + vehical.fitnaceCirtificate} target='_blank'>
                <button type="button" className="Upload-document" data-bs-dismiss="modal"><i className="fa fa-file-pdf-o" />Fitness Certificate</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewVehical