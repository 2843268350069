import React, {useState} from 'react'
import useAsync from '../hooks/useAsync'
import AdminServices from '../services/AdminServices'
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import { Link } from 'react-router-dom'
import AdminUpdate from './Update Model/UpdateAdmin'
import UpdateAdmin from './Update Model/UpdateAdmin';

Modal.setAppElement('#root');

function AdminUser() {
  const {data , error, isLoading, run} = useAsync(AdminServices.getAllAdmin);
  const count = data?.length
  console.log(data)
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  
  const handleEditDetails = (admin) => {
    setSelectedEdit(admin);
    setIsEditModalOpen(true);
  };

  const handleDelete = (admin) => {
    setSelectedEdit(admin);
    setIsDeleteModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;

  }
  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Admin Users<span className="badge bg-orange">{count}</span></h2>
          <div className="container-box-top-header-right d-flex">
            <Link className="round-add-btn" to="/add-admin-user"><img src="img/plus.svg" alt="" /></Link>
          </div>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Admin Users</p>
          </div>
         
        </div>
        <div className="container-box px-0 user-manager">
          <div className="container-box-top-header px-4">
            
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{width: '100%'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Type</th>
                  <th>Name</th>
                  <th>Mobile No.</th>
                  <th>City</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Pincode</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {data?.map((admin, index) => (
                <tr key={index}>
                <th>{index+1}</th>
                  <td><span className="usertype">{admin.userType}</span></td>
                  <td>{admin.name}</td>
                  <td>{admin.mobileNo}</td>
                  <td>{admin.city}</td>
                  <td>{admin.email}</td>
                  <td>{formatDateTime(admin.createdAt)}</td>
                  <td>{admin.pincode}</td>
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(admin)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(admin)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <UpdateAdmin admin={selectedEdit} closeModal={closeEditModal} onSuccess={run}/>
        </Modal>
        </div>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="Admin" closeModal={closeDeleteModal}  onSuccess={run}/>
        </Modal>
      </div>
      </>
  )
}

export default AdminUser