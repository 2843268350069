import React, { useState, useEffect } from 'react'
import useAsync from '../hooks/useAsync'
import RequestServies from '../services/RequestServices'
import ViewEnquiry from './View Model/ViewEnquiry'
import Modal from 'react-modal';
import HelpTogal from './TogelButton/HelpTogal'
import DeleteButton from './Button/DeleteButton'
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function Enquiry() {
  const { data, error, isLoading, run } = useAsync(RequestServies.getAllEnquiry);
  const count = data?.data?.length;

  const [isEnquiryViewModalOpen, setIsEnquiryViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEnquiry, setFilteredEnquiry] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setFilteredEnquiry(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredEnquiry(data?.data || []);
    } else {
      const filtered = data?.data?.filter((enquiry) =>
        enquiry?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enquiry?.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enquiry?.message.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEnquiry(filtered);
    }
  }, [searchTerm, data]);

  const handleViewDetails = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsEnquiryViewModalOpen(true);
  };

  const handleDelete = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsDeleteModalOpen(true);
  };

  const closeEnquiryViewModal = () => {
    setIsEnquiryViewModalOpen(false);
    setSelectedEnquiry(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEnquiry(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;
  }

  const handleDownload = () => {
    const enquiriesToDownload = filteredEnquiry.map(enquiry => ({
      'User Name': enquiry.name,
      'Contact No.': enquiry.phone,
      'Message': enquiry.message,
      'Registered Date': formatDateTime(enquiry.createdAt),
      'Status': enquiry.status
    }));

    const worksheet = XLSX.utils.json_to_sheet(enquiriesToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiries');

    XLSX.writeFile(workbook, 'enquiries.xlsx');
  };

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Enquiry<span className="badge bg-orange">{count}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={handleDownload}><img src="img/excel.svg" alt="Download" />Download</button>
          </div>
        </div>
        <div className="container-box px-0 user-manager">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input
                type="search"
                name="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Contact No.</th>
                  <th>Message</th>
                  <th>Registered Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredEnquiry.map((enquiry, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{enquiry.name}</td>
                    <td>{enquiry.phone}</td>
                    <td>{enquiry.message}</td>
                    <td>{formatDateTime(enquiry.createdAt)}</td>
                    <td className="status-toggle">
                      <HelpTogal help={enquiry} page="Enquiry" onSuccess={run} />
                    </td>
                    <td>
                      <div className="action_toggle_outer">
                        <button className="action_toggle" onClick={() => toggleActionMenu(index)}>
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleViewDetails(enquiry)}>View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(enquiry)}>Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEnquiryViewModalOpen}
          onRequestClose={closeEnquiryViewModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewEnquiry data={selectedEnquiry} closeModal={closeEnquiryViewModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEnquiry} page="Enquiry" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  )
}

export default Enquiry;
