import React from 'react'
import RequestServies from '../../services/RequestServices'
import NotificationServices from '../../services/NotificationServices';
import HomesliderServices from '../../services/HomeSliderServices';
import AdminServices from '../../services/AdminServices';
function DeleteButton({data,page,closeModal,onSuccess}) {


  const handleDelete = async () => {
  if(page === 'Enquiry'){
    const res = await RequestServies.deleteEnquiry(data._id);
    
    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  
  }else if (page === 'Help') {

    const res = await RequestServies.deleteHelp(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'Add') {

    const res = await RequestServies.deleteAdevertis(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'HomeSlider') {

    const res = await HomesliderServices.deleteHomeSlider(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'notification') {

    const res = await NotificationServices.deleteNotification(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'Admin') {

    const res = await AdminServices.deleteAdmin(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  
  }


  return (
    <div className="modal fade show d-block" id="deleteACModel" tabIndex="-1" aria-labelledby="deleteACModelLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content modal-bg-light-green">
          <div className="modal-body py-4 px-5 text-center">
              <h4>Warning !!</h4>
              <p className="desc my-2">Are you sure you want to delete This Detail ?</p>
              <div className="donate-btn-grp text-center py-4">
                  <button className="site-btn btn-blue delete-btn" onClick={closeModal}>Cancel</button>
                  <button className="site-btn btn-red delete-btn" onClick={handleDelete}>Delete </button>
              </div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default DeleteButton