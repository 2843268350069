import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="refund-policy">
            <h1>Refund Policy</h1>
            <p><strong>Refund Policy for Federation Of Customer Association (FACA)</strong></p>
            <p>Effective Date: 26/07/2024</p>

            <h2>1. Overview</h2>
            <p>Our refund policy ensures that customers are treated fairly and receive appropriate remedies when necessary. This policy outlines the conditions under which refunds may be granted.</p>

            <h2>2. Eligibility for Refunds</h2>
            <p>Refunds will be considered under the following circumstances:</p>
            <ul>
                <li>If a product or service provided does not meet the promised standards.</li>
                <li>If there are significant defects or issues with the product or service.</li>
                <li>If the customer cancels the service within a specified period.</li>
            </ul>

            <h2>3. Process for Requesting Refunds</h2>
            <p>To request a refund, customers must contact us within 24 hours of receiving the product or service. The request should include a detailed description of the issue and any relevant supporting documentation.</p>

            <h2>4. Processing Refunds</h2>
            <p>Once a refund request is received and reviewed, we will notify the customer of the approval or rejection of the refund. Approved refunds will be processed within 42 days, and a credit will be applied to the original method of payment.</p>

            <h2>5. Changes to Refund Policy</h2>
            <p>We reserve the right to modify this refund policy at any time. Any changes will be effective immediately upon posting on our website.</p>
        </div>
    );
};

export default RefundPolicy;
